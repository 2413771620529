import PropTypes from 'prop-types';

//kształt wymaganych typów danych z API
export const ItemShape = {
  talk_id: PropTypes.number.isRequired,
  lead_number: PropTypes.string.isRequired,
  start_time: PropTypes.string,
  phone_number: PropTypes.string,
  // logical_status: PropTypes.string.isRequired,
  technical_status: PropTypes.string,
  // business_status: PropTypes.string.isRequired,
  // processing_status: PropTypes.string.isRequired,
  duration: PropTypes.number,
};
