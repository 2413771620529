import React, { useEffect, useState, useContext } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import axios from 'axios';

import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import Checkbox from '@mui/material/Checkbox';
import { useLocation } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // --> https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react
import { faArrowUp, faArrowDown, faRedo } from '@fortawesome/free-solid-svg-icons'; // --> https://fontawesome.com/v5.15/how-to-use/on-the-web/using-with/react

import { api } from '../../../api/api';
import { GlobalContext } from '../../../context/GobalState';

import Pagination from '../../organisms/Pagination/Pagination';
import TreeSelectMenu from '../../organisms/TreeSelect/TreeSelectMenu';
import Modal from 'components/atoms/Modal/Modal';
import ErrorTable from 'components/atoms/ErrorTable/ErrorTable';
import Item from 'components/molecules/Item/Item';
import Spinner from 'components/molecules/Spinner/Spinner';
import ModalContent from 'components/atoms/ModalContent/ModalContent';
import Input from 'components/atoms/Input/Input';
import TimeInput from 'components/atoms/TimeInput/TimeInput';

import './Table.scss';
import {
  Table,
  TableHeader,
  FirstRowHeader,
  SecondRowHeader,
  SortBtn,
  DurationTable,
  StatusCell,
  DateTimeCell,
  PaginationRow,
  TableWrapper,
  DateTimePickerWrapper,
  dateTimeTextFieldStyle,
} from './TableComponent.styles';

const TableComponent = ({
  items,
  error,
  sortColumnDesc,
  sortColumnAsc,
  onStartDuration,
  onEndDuration,
  startDuration,
  endDuration,
  addSelectedLogical,
  onPhoneNumber,
  phoneNumberValue,
  onLeadNumber,
  leadNumberValue,
  totalRows,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  addStartDateTime,
  addEndDateTime,
  startDateTimeValue,
  endDateTimeValue,
  setStartDateTimeValue,
  setEndDateTimeValue,
  resetDateTime,
  resetDuration,
  selectedItems,
  setSelectedItems,
}) => {
  const [dataModal, setDataModal] = useState([]);
  const [isModalActive, setModalActive] = useState(false);
  const [logicalOptions, setLogicalOptions] = useState([]);
  const [lastOpenItemId, setLastOpenItemId] = useState(null);

  const { authToken } = useContext(GlobalContext);
  const handleError = useErrorHandler();

  let location = useLocation();

  const toggleAll = () => {
    if (selectedItems.length === items.map((item) => item.talk_id).length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(items.map((item) => item.talk_id));
    }
  };

  useEffect(() => {
    const leadIdFromUrl = location.pathname;

    if (leadIdFromUrl.includes('!')) {
      const result = leadIdFromUrl.substring(leadIdFromUrl.lastIndexOf('!') + 1);
      if (!!result) {
        addDataToModal({ talk_id: result });
        window.history.pushState('', '', '/items-list');
      }
    }
  }, [location, location.pathname]);

  useEffect(() => {
    if (authToken) {
      axios
        .get(`${api}data/logical_status_distinct_values/?campaign=Neuca`, {
          headers: {
            Authorization: `token ${authToken}`,
          },
        })
        .then(({ data }) => {
          setLogicalOptions(data.data);
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [handleError, authToken]);

  const addDataToModal = (data) => {
    setModalActive(true);
    setDataModal([data]);
  };

  const getColumnSortDesc = (e) => {
    sortColumnDesc(e);
  };

  const getColumnSortAsc = (e) => {
    sortColumnAsc(e);
  };

  //przesyłamy do komponentu wyżej wybrane opcje filtracji statutusu logicznego
  const onMultiSelectLogical = (selected) => {
    addSelectedLogical(selected);
  };

  return (
    <TableWrapper>
      <Table>
        <TableHeader>
          <FirstRowHeader>
            <th style={{ width: '110px' }}>Select page</th>
            <th colSpan={1}>
              Lead ID
              <SortBtn type="button" name="lead_number" onClick={getColumnSortDesc}>
                <FontAwesomeIcon icon={faArrowUp} />
              </SortBtn>
              <SortBtn type="button" name="lead_number" onClick={getColumnSortAsc}>
                <FontAwesomeIcon icon={faArrowDown} />
              </SortBtn>
            </th>
            <th colSpan={1}>Phone Number</th>
            <th colSpan={1}>
              Date
              <SortBtn type="button" name="start_time" onClick={getColumnSortDesc}>
                <FontAwesomeIcon icon={faArrowUp} />
              </SortBtn>
              <SortBtn type="button" name="start_time" onClick={getColumnSortAsc}>
                <FontAwesomeIcon icon={faArrowDown} />
              </SortBtn>
              <SortBtn type="button" name="start_time" onClick={resetDateTime}>
                <FontAwesomeIcon icon={faRedo} />
              </SortBtn>
            </th>
            <th>Logical Status</th>
            <th colSpan={1}>
              Duration
              <SortBtn type="button" name="duration" onClick={getColumnSortDesc}>
                <FontAwesomeIcon icon={faArrowUp} />
              </SortBtn>
              <SortBtn type="button" name="duration" onClick={getColumnSortAsc}>
                <FontAwesomeIcon icon={faArrowDown} />
              </SortBtn>
              <SortBtn type="button" name="start_time" onClick={resetDuration}>
                <FontAwesomeIcon icon={faRedo} />
              </SortBtn>
            </th>
          </FirstRowHeader>
          <SecondRowHeader>
            <th style={{ paddingLeft: '10px' }}>
              <Checkbox
                sx={{ padding: '0' }}
                onClick={toggleAll}
                checked={selectedItems.length === items.map((item) => item.talk_id).length && selectedItems.length > 0}
              />
            </th>
            <th>
              <Input
                debounceTimeout={300}
                type="search"
                name="lead_number"
                placeholder="wyszukaj..."
                onChange={onLeadNumber}
                value={leadNumberValue}
                autoComplete="off"
              />
            </th>
            <th>
              <Input
                debounceTimeout={300}
                type="search"
                name="phone_number"
                placeholder="wyszukaj..."
                onChange={onPhoneNumber}
                value={phoneNumberValue}
                autoComplete="off"
              />
            </th>
            <DateTimeCell style={{ flexDirection: 'column' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePickerWrapper>
                  <p>from: </p>
                  <DateTimePicker
                    renderInput={(props) => <TextField sx={dateTimeTextFieldStyle} {...props} />}
                    value={startDateTimeValue}
                    onChange={(newValue) => {
                      addStartDateTime(newValue);
                      setStartDateTimeValue(newValue);
                    }}
                    ampm={false}
                  />
                </DateTimePickerWrapper>
                <DateTimePickerWrapper>
                  <p>to: </p>
                  <DateTimePicker
                    renderInput={(props) => <TextField sx={dateTimeTextFieldStyle} {...props} />}
                    value={endDateTimeValue}
                    onChange={(newValue) => {
                      addEndDateTime(newValue);
                      setEndDateTimeValue(newValue);
                    }}
                    ampm={false}
                  />
                </DateTimePickerWrapper>
              </LocalizationProvider>
            </DateTimeCell>
            <StatusCell>
              <TreeSelectMenu data={logicalOptions} onChange={onMultiSelectLogical} />
            </StatusCell>
            <DateTimeCell>
              <DurationTable>
                <tbody>
                  <tr>
                    <th>
                      <label htmlFor="duration-from">
                        <p style={{ width: '40px' }}> from:</p>
                        <TimeInput value={startDuration} onChange={onStartDuration} />
                      </label>
                    </th>
                  </tr>
                  <tr>
                    <th>
                      <label htmlFor="duration-to">
                        <p style={{ width: '40px' }}> to:</p>
                        <TimeInput value={endDuration} onChange={onEndDuration} />
                      </label>
                    </th>
                  </tr>
                </tbody>
              </DurationTable>
            </DateTimeCell>
          </SecondRowHeader>
        </TableHeader>
        <tbody>
          {items.length > 0 ? (
            items.map((item) => (
              <Item
                key={item.talk_id}
                checked={selectedItems.includes(item.talk_id)}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                item={item}
                addDataToModal={addDataToModal}
                isLastOpenItem={lastOpenItemId === item.talk_id}
              />
            ))
          ) : error ? (
            <ErrorTable label={error} />
          ) : (
            <Spinner />
          )}
          <PaginationRow>
            <td colSpan={6}>
              <Pagination
                totalRows={totalRows}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </td>
          </PaginationRow>
        </tbody>
      </Table>
      <Modal isActive={isModalActive} setActive={() => setModalActive(false)}>
        <ModalContent data={dataModal} isActive={isModalActive} setLastOpenItemId={setLastOpenItemId} />
      </Modal>
    </TableWrapper>
  );
};

export default TableComponent;
