import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper } from './ErrorTable.styles';

const ErrorTable = ({ label }) => {
  return (
    <Wrapper>
      <td>
        <p>{label}</p>
      </td>
    </Wrapper>
  );
};

ErrorTable.propTypes = {
  label: PropTypes.string.isRequired,
};

export default ErrorTable;
