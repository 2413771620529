import styled from 'styled-components';

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  height: 64px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.bgDark};
  justify-content: space-between;
  // background: ${({ theme }) => theme.colors.darkBlue};
  padding: 0 30px;
`;

export const Logo = styled.div`
  img {
    width: 80px;
  }
`;
