import React, { useState, useEffect } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { Checkbox } from '@material-ui/core';

import { StyledTreeSelectItem, StyleTreeSelectView } from './TreeSelect.styles';

const TreeSelect = ({ data, onChange, selectAll }) => {
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    onChange({ id: data.id, selected: selected.map((obj) => obj.key) });
  }, [selected]);

  useEffect(() => {
    if (selectAll) {
      setSelected([...data.children, data]);
    } else {
      setSelected([]);
    }
  }, [selectAll, data]);

  function getChildById(node, id) {
    let array = [];

    function getAllChild(nodes) {
      if (nodes === null) return [];
      array.push(nodes);
      if (Array.isArray(nodes.children)) {
        nodes.children.forEach((node) => {
          array = [...array, ...getAllChild(node)];
          array = array.filter((v, i) => array.indexOf(v) === i);
        });
      }
      return array;
    }

    function getNodeById(nodes, id) {
      if (nodes.id === id) {
        return nodes;
      } else if (Array.isArray(nodes.children)) {
        let result = null;
        nodes.children.forEach((node) => {
          if (!!getNodeById(node, id)) {
            result = getNodeById(node, id);
          }
        });
        return result;
      }

      return null;
    }

    return getAllChild(getNodeById(node, id));
  }

  function getOnChange(checked, nodes) {
    const allNode = getChildById(data, nodes.id);
    let array = checked ? [...selected, ...allNode] : selected.filter((value) => !allNode.includes(value));

    array = array.filter((v, i) => array.indexOf(v) === i);

    setSelected(array);
  }

  const isChecked = (nodes) => {
    return selected.some((item) => item.id === nodes.id);
  };

  // TO-DO: To fix hasChildren Warning migrate TreeItem to MUIv5 and pass hasChildren in ContentProps object
  const renderTree = (nodes) => (
    <StyledTreeSelectItem
      hasChildren={Array.isArray(nodes.children)}
      key={nodes.id}
      nodeId={nodes.id}
      label={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox
            checked={isChecked(nodes)}
            onChange={(event) => getOnChange(event.currentTarget.checked, nodes)}
            onClick={(e) => e.stopPropagation()}
          />
          <p style={{ fontSize: '12px' }}>{nodes.key}</p>
        </div>
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </StyledTreeSelectItem>
  );

  return (
    <StyleTreeSelectView
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={['0']}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      {renderTree(data)}
    </StyleTreeSelectView>
  );
};

export default TreeSelect;
