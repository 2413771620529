import styled from 'styled-components';

import { default as MaterialButton } from '@material-ui/core/Button';

const StyledButton = styled(MaterialButton)`
  white-space: nowrap;
  border-radius: 6px;
  ${({ contained, theme }) =>
    contained &&
    `
  background-color: ${theme.colors.primary};
  
  &:hover {
    background-color: ${theme.colors.darkBlue2};
  }
  }`};

  color: ${({ theme, white }) => (white ? theme.colors.white : theme.colors.textBlack)};
  padding: 7px 14px;
`;
const Button = ({ children, contained, white, ...otherProps }) => {
  return (
    <StyledButton white={white ? 1 : 0} contained={contained ? 1 : 0} {...otherProps}>
      {children}
    </StyledButton>
  );
};

export default Button;
