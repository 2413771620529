import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, StyledContent, CloseButton } from './Modal.styles';
import CloseIcon from '@material-ui/icons/Close';

const Modal = ({ children, isActive, setActive }) => {
  return (
    <Wrapper isActive={isActive} onClick={() => setActive(false)}>
      <StyledContent isActive={isActive} onClick={(e) => e.stopPropagation()}>
        {children}
        <CloseButton onClick={() => setActive(false)}>
          <CloseIcon />
        </CloseButton>
      </StyledContent>
    </Wrapper>
  );
};

Modal.propTypes = {
  isActive: PropTypes.bool.isRequired,
  setActive: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

export default Modal;
