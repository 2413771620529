export const theme = {
  colors: {
    primary: '#1a73e8',
    white: '#fff',
    green: '#04AA6D',
    darkBlue: '#003186',
    darkBlue2: '#6200ee',
    lightBlue: '#8fbefa',
    lightGrey: '#F7F8FA',
    lightGrey2: '#dadce0',
    mediumBlue: '#6eadff',
    darkGrey: '#3b3b3b',
    veryLightBlue: '#dbe9ff',
    veryLightBlue2: '#f8f8f9',
    grey: '#969696',
    greyRow: '#ededed',
    veryLightBlueRow: '#edf4ff',
    error: '#f44336',
    success: '#00cc00',
    deleteColor: '#ff6666',
    confirm: '#34eb3a',
    abort: 'red',
    bgDark: '#202530',
    bgLightGrey: '#f2f2f2',
    textBlack: '#222222',
  },
  fontSize: {
    xxl: '32px',
    xl: '24px',
    l: '17px',
    m: '16px',
    s: '13px',
  },
};
