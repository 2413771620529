import React from 'react';
import Navigation from 'components/organisms/Navigation/Navigation';
import Wrapper from './MainTemplate.styles';
import PropTypes from 'prop-types';

const MainTemplate = ({ children, logout }) => {
  return (
    <Wrapper>
      <Navigation logout={logout} />
      {children}
    </Wrapper>
  );
};

MainTemplate.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainTemplate;
