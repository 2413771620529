import React, { useState, useEffect, useContext } from 'react';
import { ThemeProvider } from 'styled-components';
import { useIdleTimer } from 'react-idle-timer';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core/styles';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { GlobalContext } from '../context/GobalState';
import useToken from '../hooks/useToken';
import { logoutUser } from '../api/api';

import Login from 'components/organisms/Login/Login';
import PasswordChange from 'components/organisms/PasswordChange/PasswordChange';
import ItemsList from 'components/organisms/ItemsList/ItemsList';
import ErrorBoundary from 'components/organisms/ErrorBoundary/ErrorBoundary';
import MainTemplate from 'components/templates/MainTemplate/MainTemplate';

import { GlobalStyle } from 'assets/styles/GlobalStyle';
import { theme } from 'assets/styles/theme';
import Wrapper from './Root.styles';
import favicon from 'assets/images/vee_favicon.png';

//Główny komponent Rootowy
const Root = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [forcePasswordChange, setForcePasswordChange] = useState(false);
  const { token, setToken } = useToken();
  const { setAuthToken, setPermissionLevel } = useContext(GlobalContext);

  const handleOnIdle = () => {
    if (isLoggedIn) {
      logout();
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    if (token?.token) {
      setIsLoggedIn(true);
      setForcePasswordChange && setForcePasswordChange(token.force_password_change);
      setPermissionLevel && setPermissionLevel(token.permitted_panel);
      setAuthToken && setAuthToken(token.token);
    } else {
      setIsLoggedIn(false);
    }
  }, [token, setAuthToken, setPermissionLevel]);

  const logout = () => {
    logoutUser(token.token);
    setToken('');
    setIsLoggedIn(false);
  };

  if (!isLoggedIn) {
    return (
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <Helmet link={[{ rel: 'icon', type: 'image/png', href: favicon }]} />
          <Login setToken={setToken} />
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  if (isLoggedIn && forcePasswordChange) {
    return (
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <Helmet link={[{ rel: 'icon', type: 'image/png', href: favicon }]} />
          <PasswordChange
            setForcePasswordChange={setForcePasswordChange}
            logout={logout}
            token={token}
            setToken={setToken}
          />
        </ThemeProvider>
      </HelmetProvider>
    );
  }

  return (
    <HelmetProvider>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <ErrorBoundary setIsLoggedIn={setIsLoggedIn}>
            <Router>
              <GlobalStyle />
              <MainTemplate logout={logout}>
                <Wrapper>
                  <Switch>
                    <Route exact path="/">
                      <Helmet link={[{ rel: 'icon', type: 'image/png', href: favicon }]} />
                      <Redirect to="/items-list" />
                    </Route>
                    <Route path="/items-list">
                      <ItemsList />
                    </Route>
                  </Switch>
                </Wrapper>
              </MainTemplate>
            </Router>
          </ErrorBoundary>
        </ThemeProvider>
      </StylesProvider>
    </HelmetProvider>
  );
};

export default Root;
