import React from 'react';
import PropTypes from 'prop-types';
import TablePaginationActions from 'components/molecules/TablePaginationActions/TablePaginationActions';
import { Wrapper, TablePagination } from './Pagination.styles';

const Pagination = ({ totalRows, rowsPerPage, page, handleChangePage, handleChangeRowsPerPage }) => {
  return (
    <table style={{ width: '100%' }}>
      <tbody>
        <Wrapper>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            count={totalRows}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
            sx={{ minHeight: '40px' }}
          />
        </Wrapper>
      </tbody>
    </table>
  );
};

Pagination.propTypes = {
  totalRows: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
};

export default Pagination;
