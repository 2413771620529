import React from 'react';
import ReactDOM from 'react-dom';
import Root from 'views/Root';
import { GlobalProvider } from '../src/context/GobalState';

ReactDOM.render(
  <React.StrictMode>
    <GlobalProvider>
      <Root />
    </GlobalProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
