import React from 'react';
import PropTypes from 'prop-types';
import { ItemShape } from 'types';
import { TableRow, LeadNumberCell, PrefixPhone, CenterCell } from './Item.styles';
import { formatDuration, formatPhoneNumber } from 'helpers';
import Checkbox from '@mui/material/Checkbox';
import _ from 'lodash';

const Item = ({
  item: { talk_id, lead_number, start_time, duration, phone_number, logical_status },
  checked,
  addDataToModal,
  selectedItems,
  setSelectedItems,
  isLastOpenItem,
}) => {
  const openModal = () => {
    addDataToModal({ talk_id, phone_number });
  };
  return (
    <>
      <TableRow onClick={openModal} isLastOpenItem={isLastOpenItem}>
        <CenterCell>
          <Checkbox
            sx={{ padding: '0' }}
            checked={checked}
            onClick={(e) => {
              e.stopPropagation();
              if (selectedItems.includes(talk_id)) {
                let selectedItemsCopy = selectedItems;

                _.remove(selectedItemsCopy, (number) => number === talk_id);
                setSelectedItems([...selectedItemsCopy]);
              } else setSelectedItems([...selectedItems, talk_id]);
            }}
          />
        </CenterCell>
        <LeadNumberCell>{lead_number}</LeadNumberCell>
        <CenterCell>
          {phone_number && (
            <>
              <PrefixPhone>{phone_number.startsWith('+48') ? `${phone_number.slice(0, 3)}` : ''}</PrefixPhone>
              {formatPhoneNumber(phone_number)}
            </>
          )}
        </CenterCell>
        <CenterCell>{start_time}</CenterCell>
        <CenterCell>{logical_status}</CenterCell>
        <CenterCell>{formatDuration(duration)}</CenterCell>
      </TableRow>
    </>
  );
};

Item.propTypes = {
  item: PropTypes.shape(ItemShape),
  addDataToModal: PropTypes.func.isRequired,
};

export default Item;
