import styled from 'styled-components';
import { default as MaterialTablePagination } from '@material-ui/core/TablePagination'; // https://material-ui.com/api/table-pagination/

export const Wrapper = styled.tr`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .MuiTablePagination-root {
    border: none;
  }

  td {
    padding: 0;
  }
}
`;

export const TablePagination = styled(MaterialTablePagination)``;
