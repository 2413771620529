import styled from 'styled-components';

import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';

export const StyledTreeSelectItem = styled(TreeItem)`
  .MuiTreeItem-group {
    margin-left: 10px;
  }

  ${({ hasChildren }) =>
    !hasChildren &&
    `
.MuiTypography-root: {
  background: white !important;
  cursor: default;
}

.MuiTreeItem-label {
  background: white !important;
  cursor: default;
}

.MuiTreeItem-content {
  background: white !important;
  cursor: default;
}
`}
`;

export const StyleTreeSelectView = styled(TreeView)`
  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:not(:hover) {
    background-color: white;
  }

  // .MuiTypography-root .MuiTreeItem-label .MuiTypography-body1:focus {
  //   background: white;
  // }

  .MuiTreeItem-root:focus:not(:hover) > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: transparent;
  }
`;

export const MenuContent = styled.div`
  padding: 0 10px;
  min-width: 330px;
  min-height: 120px;
  max-height: 450px;
`;

export const MenuContentHeader = styled.div`
  display: flex;
`;
