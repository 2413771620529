import React from 'react';
import { Wrapper, Logo } from './Navigation.styles';
import logoImg from '../../../assets/images/vee_logo_for_dark_bg.png';
import Menu from 'components/molecules/Menu/Menu';

const Header = ({ logout }) => {
  return (
    <Wrapper>
      <Logo>
        <img src={logoImg} alt="vee" />
      </Logo>
      <Menu logout={logout} />
    </Wrapper>
  );
};

export default Header;
