import React, { useState, useEffect } from 'react';

import Menu from '@material-ui/core/Menu';
import { Checkbox } from '@material-ui/core';

import TreeSelect from './TreeSelect';
import Button from '../../atoms/Button/Button';

import { MenuContent, MenuContentHeader } from './TreeSelect.styles';

const TreeSelectWrapper = ({ data, onChange }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedArrays, setSelectedArrays] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    onChange(selectedArrays.map((obj) => obj.selected).flat());
  }, [selectedArrays]);

  useEffect(() => {
    if (!selectAll) {
      setSelectedArrays([]);
    }
  }, [selectAll]);

  const selectedArrHandler = (obj) => {
    if (selectedArrays.some((selectedObj) => selectedObj.id === obj.id)) {
      const mapa = selectedArrays.map((arr) => (arr.id === obj.id ? obj : arr));
      setSelectedArrays(mapa);
    } else {
      setSelectedArrays((selectedArrays) => [...selectedArrays, obj]);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleSelectAll = () => {
    setSelectAll(!selectAll);
  };
  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        style={{ height: '29px', borderRadius: '4px', textTransform: 'none' }}
      >
        Select
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        style={{ overflowY: 'scroll' }}
      >
        <MenuContent>
          <MenuContentHeader>
            <Button
              style={{ height: '29px', margin: '0 0 8px 8px', textTransform: 'none' }}
              onClick={() => toggleSelectAll()}
            >
              <Checkbox
                checked={selectAll}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleSelectAll();
                }}
              />
              {selectAll ? 'Unselect all' : 'Select all'}
            </Button>
          </MenuContentHeader>
          {data &&
            data.map((log, index) => (
              <TreeSelect key={`${index}${log.id}`} data={log} onChange={selectedArrHandler} selectAll={selectAll} />
            ))}
        </MenuContent>
      </Menu>
    </div>
  );
};

export default TreeSelectWrapper;
