import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import { ChangePassword } from '../../../api/api';
import { GlobalContext } from '../../../context/GobalState';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import LinearProgress from '@mui/material/LinearProgress';

import TextField from '../../atoms/TextFiled/TextField';
import Button from '../../atoms/Button/Button';

import logo from '../../../assets/images/vee_logo_for_light_bg.png';

import { StyledErrorMessage } from './PasswordChange.styles';

const PasswordChange = ({ setForcePasswordChange, logout, token, setToken }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isErrorShown, setIsErrorShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { authToken } = useContext(GlobalContext);

  useEffect(() => {
    if (!!errorMessage?.length) {
      setIsErrorShown(true);
    } else {
      setIsErrorShown(false);
    }
  }, [errorMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!oldPassword?.length || !newPassword?.length || !confirmedPassword?.length) {
      setErrorMessage('Enter "Old password", "New password" and "Confirm new password"');
      return;
    }
    if (newPassword !== confirmedPassword) {
      setErrorMessage('"New password" and "Confirm new password" are not the same');
      return;
    }

    setIsLoading(true);
    try {
      const changePasswordData = await ChangePassword(authToken, {
        old_password: oldPassword,
        new_password: newPassword,
        confirmed_password: confirmedPassword,
      });
      if (changePasswordData?.status === 200) {
        setForcePasswordChange(false);
        token.force_password_change = false;
        setToken(token);
      } else if (changePasswordData?.response?.status === 401) {
        setIsLoading(false);
        setErrorMessage(changePasswordData.response?.data?.message);
      } else if (changePasswordData?.response?.status === 400) {
        setIsLoading(false);
        if (changePasswordData.response?.data?.message) {
          setErrorMessage(changePasswordData.response?.data?.message);
        }
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        mt={10}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box mb={4}>
          <img src={logo} alt="vee" />
        </Box>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
          <Button contained white onClick={() => logout()}>
            logout
          </Button>
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, position: 'relative' }}>
          <LinearProgress sx={{ visibility: isLoading ? 'visible' : 'hidden' }} />
          <Box mb={!!errorMessage ? 0 : 2}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="oldPassword"
              label="Old password"
              type="Password"
              name="oldPassword"
              autoComplete=""
              onChange={(e) => setOldPassword(e.target.value)}
              autoFocus
              variant="outlined"
              error={isErrorShown}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="newPassword"
              label="New password"
              type="Password"
              id="newPassword"
              onChange={(e) => setNewPassword(e.target.value)}
              autoComplete="current-password"
              variant="outlined"
              error={isErrorShown}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="confirmNewPassword"
              label="Confrim new password"
              type="Password"
              id="confirmNewPassword"
              onChange={(e) => setConfirmedPassword(e.target.value)}
              autoComplete="current-password"
              variant="outlined"
              error={isErrorShown}
            />
            {!!errorMessage?.length && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
          </Box>
          <Button white type="submit" fullWidth contained color="primary">
            Change password and log in
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordChange;
