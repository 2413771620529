import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.nav``;

const activeClassName = 'active-link';
export const StyledLink = styled(NavLink).attrs({ activeClassName })`
  font-weight: bold;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.white};
  text-align: right;
  margin: 15px 20px 15px auto;
  position: relative;
  transition: 0.2s;

  &.${activeClassName} {
    color: ${({ theme }) => theme.colors.confirm};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.confirm};
    transform: scale(2.2);
  }
`;
