import styled from 'styled-components';

import TreeView from '@material-ui/lab/TreeView';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import TreeItem from '@material-ui/lab/TreeItem';

const StyledTreeItem = styled(TreeItem)`
  p {
    font-size: 14px;
    font-family: Poppins;
    span {
      font-weight: 600;
    }
  }

  .MuiTreeItem-group {
    margin-left: 25px;
  }

  .MuiTreeItem-content {
    margin-bottom: 6px;
  }

  .MuiTypography-root .MuiTreeItem-label .MuiTypography-body1:focus {
    background: white;
  }

  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: white;
  }

  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label:hover,
  .MuiTreeItem-root.Mui-selected:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: white;
  }

  .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: white;
  }

  .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label {
    background-color: white !important;
  }

  ${({ hasChildren }) =>
    !hasChildren &&
    `
.MuiTypography-root {
  background: white !important;
  cursor: default;
}

.MuiTreeItem-label {
  background: white !important;
  cursor: text;
}

.MuiTreeItem-content {
  background: white !important;
  cursor: default;
}
`}
`;

const StyledTreeView = styled(TreeView)`
  .MuiTreeItem-root {
    position: relative;
    left: -3px;
  }

  .MuiTypography-root .MuiTreeItem-label .MuiTypography-body1:focus {
    background: white;
  }

  .MuiTreeItem-root:focus > .MuiTreeItem-content .MuiTreeItem-label:not(:hover) {
    background-color: transparent;
  }
`;

const Tree = ({ data }) => {
  const renderTree = (nodes) => (
    <StyledTreeItem
      hasChildren={Array.isArray(nodes.children)}
      key={nodes.id}
      nodeId={`${nodes.id}`}
      label={
        <p>
          <span>{`${nodes.key}`} </span>
          {`${nodes.value}`}
        </p>
      }
    >
      {Array.isArray(nodes.children) ? nodes.children.map((node) => renderTree(node)) : null}
    </StyledTreeItem>
  );

  return (
    <StyledTreeView
      disableSelection
      aria-label="rich object"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 110, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {renderTree(data)}
    </StyledTreeView>
  );
};

export default Tree;
