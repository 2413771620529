import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Icon = styled(FontAwesomeIcon)`
  margin: 0 5px;
`;

export const StyledHeader = styled.header`
  p,
  span {
    font-size: 14px;
  }
`;

export const HeaderInfo = styled.div`
  margin-top: 24px;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    span {
      font-weight: bold;
      margin-right: 4px;
    }
  }
`;

export const AudioRow = styled.div`
  margin-top: 26px;
  display: flex;
  align-items: center;

  audio {
    width: 100%;
  }

  div {
    display: flex;
    button {
      margin-right: 10px;
    }
  }
`;

export const StyledContent = styled.main`
  p,
  span {
    font-size: 14px;
  }
  height: 70vh;
  margin: 16px 0;
  padding-top: 10px;
  overflow-y: scroll;
  ${({ showBorderTop, theme }) => showBorderTop && `border-top: 1px solid ${theme.colors.lightGrey2}`};
  ${({ showBorderBottom, isScrolledToBottom, theme }) =>
    showBorderBottom && !isScrolledToBottom && `border-bottom: 1px solid ${theme.colors.lightGrey2}`};
`;

export const ConnectionInfo = styled.div`
  padding: 0 10px;
  margin-bottom: 24px;
`;

export const ConnectionDetails = styled.div`
  background: ${({ theme }) => theme.colors.veryLightBlue2};

  display: flex;
  flex-wrap: wrap;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey2};
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  padding: 9px 16px;

  p {
    margin-bottom: 0;
    margin-right: 8px;
    font-size: 14px;
    span {
      font-size: 14px;
      font-weight: 600;
    }
  }
`;

export const ConversationLog = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  align-items: flex-start;

  h4 {
    margin: 0;
  }
  & > p {
    margin-bottom: 6px;
  }
  p {
    span {
      font-weight: 600;
    }
  }
  .details-vector {
    margin-bottom: px;
    p {
      margin: 0;

      margin-bottom: 6px;
      margin-right: 6px;
      span {
        margin-right: 4px;
      }
    }
  }

  .details-vector-info {
    margin-left: 60px;
    display: flex;
    flex-wrap: wrap;
    p {
      margin-bottom: 0;
    }
  }

  .details-vector-dc {
    margin-bottom: 6px;
    margin-left: 60px;
  }

  .hi-scoring {
    margin-bottom: 6px;

    & > p {
      cursor: pointer;
    }

    p {
      .chevron-icon {
        display: inline-block;
        width: 13px;

        svg {
          margin: 0;
        }
      }
    }

    display: flex;
    div {
      p {
        margin-left: 4px;
      }

      p:not(:last-child) {
        margin-bottom: 2px;
      }
    }
  }
`;

export const BorderContainer = styled.div`
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-left: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
  & > div:first-child:not(:only-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
  }
`;

export const LogWrapper = styled.div`
  display: flex;
`;
