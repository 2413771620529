//formatowanie kolumny Czas rozmowy
export const formatDuration = (totalSeconds) => {
  totalSeconds %= 3600;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = totalSeconds % 60;
  minutes = String(minutes).padStart(2, '0');
  seconds = String(seconds).padStart(2, '0');
  return `${minutes}:${seconds}`;
};

//formatowanie kolumny Data
export const formatDateTime = (string) => {
  const regDate = /([0-9]{4})-([0-9]{2})-([0-9]{2})/g;
  const regTime = /([0-9]{2}):([0-9]{2}):([0-9]{2})/g;
  const date = string.match(regDate).join().split('-').reverse().join('/');
  const time = string.match(regTime).reverse();
  return `${date} ${time}`;
};

//proste formatowanie numeru telefonu
export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber.startsWith('+48')) {
    return `${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 9)} ${phoneNumber.slice(9)}`;
  } else if (phoneNumber.startsWith('+048')) {
    return `${phoneNumber.slice(3, 6)} ${phoneNumber.slice(6, 9)} ${phoneNumber.slice(9)}`;
  } else {
    return phoneNumber.replace(/^(\d{3})(\d{3})(\d{3})$/g, '$1 $2 $3');
  }
};

export const setInputFilter = (textbox, inputFilter) => {
  ['input', 'keydown', 'keyup', 'mousedown', 'mouseup', 'select', 'contextmenu', 'drop'].forEach(function (event) {
    textbox.addEventListener(event, function () {
      if (inputFilter(this.value)) {
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd;
      } else if (this.hasOwnProperty('oldValue')) {
        this.value = this.oldValue;
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
      } else {
        this.value = '';
      }
    });
  });
};

export const insert = (arr, index, newItem) => [...arr.slice(0, index), newItem, ...arr.slice(index)];
