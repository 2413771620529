import React from 'react';
import { Wrapper, StyledSpinner } from './Spinner.styles';

const Spinner = () => {
  return (
    <Wrapper>
      <StyledSpinner>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </StyledSpinner>
    </Wrapper>
  );
};

export default Spinner;
