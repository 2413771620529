import React, { useState, useEffect, useContext } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import axios from 'axios';
import { useDebounce } from 'use-debounce';
import { api } from '../../../api/api';

import LoadingButton from '@mui/lab/LoadingButton';
import Container from '@material-ui/core/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faDownload } from '@fortawesome/free-solid-svg-icons';

import { GlobalContext } from '../../../context/GobalState';
import { insert } from '../../../helpers';

import Button from '../../atoms/Button/Button';
import TableComponent from 'components/molecules/TableComponent/TableComponent';

const ItemsList = () => {
  const [items, setItems] = useState([]);
  const [error, setError] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalRows, setTotalRows] = useState(0);
  const [numberOfConversations, setNumberOfConversations] = useState(0);
  const [searchLogicalStatus, setSearchLogicalStatus] = useState('');
  const [selectedLogicalOptions, setSelectedLogicalOptions] = useState([]);
  const [sortNameColumnDesc, setSortNameColumnDesc] = useState('');
  const [sortNameColumnAsc, setSortNameColumnAsc] = useState('');
  const [startDuration, setStartDuration] = useState('');
  const [endDuration, setEndDuration] = useState('');
  const [startDurationValue, setStartDurationValue] = useState('00:00');
  const [endDurationValue, setEndDurationValue] = useState('59:59');
  const [startDateTime, setStartDateTime] = useState('');
  const [endDateTime, setEndDateTime] = useState('');
  const [startDateTimeValue, setStartDateTimeValue] = useState(new Date());
  const [endDateTimeValue, setEndDateTimeValue] = useState(new Date());
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [leadNumberValue, setLeadNumberValue] = useState('');
  const [downloadSelectedLogsLink, setDownloadSelectedLogsLink] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);

  const [debouncedStartDuration] = useDebounce(startDuration, 500);
  const [debouncedEndDuration] = useDebounce(endDuration, 500);
  const [debouncedStartDateTime] = useDebounce(startDateTime, 500);
  const [debouncedEndDateTime] = useDebounce(endDateTime, 500);

  const { authToken } = useContext(GlobalContext);
  const handleError = useErrorHandler();

  useEffect(() => {
    let url = `https://backend.staging-neuca.vee.ai/data/data/?campaign=Neuca`;
    url += `&page_num=${page + 1}`;
    url += `&rows_per_page=${rowsPerPage}`;
    if (phoneNumberValue) {
      url += `&search_filter_phone_number=${phoneNumberValue.replace(/\s/g, '')}`;
    }
    if (leadNumberValue) {
      url += `&search_filter_lead_nr=${leadNumberValue}`;
    }
    if (searchLogicalStatus) {
      url += `&logical_status=${searchLogicalStatus}`;
    }
    if (sortNameColumnDesc) {
      url += `&sorting_filter=${sortNameColumnDesc}&sorting_direction=desc`;
    }
    if (sortNameColumnAsc) {
      url += `&sorting_filter=${sortNameColumnAsc}`;
    }

    if (debouncedStartDuration && debouncedEndDuration) {
      url += `&start_duration=${debouncedStartDuration}&end_duration=${debouncedEndDuration}`;
    }
    if (debouncedStartDuration && !debouncedEndDuration) {
      url += `&start_duration=${debouncedStartDuration}&end_duration=${'59:59'}`;
    }
    if (!debouncedStartDuration && debouncedEndDuration) {
      url += `&start_duration=${'00:00'}&end_duration=${debouncedEndDuration}`;
    }

    if (debouncedStartDateTime && debouncedEndDateTime) {
      url += `&start_date=${debouncedStartDateTime}&end_date=${debouncedEndDateTime}`;
    }
    if (debouncedStartDateTime && !debouncedEndDateTime) {
      url += `&start_date=${debouncedStartDateTime}&end_date=${new Date().toISOString()}`;
    }
    if (!debouncedStartDateTime && debouncedEndDateTime) {
      url += `&start_date=${new Date().toISOString()}&end_date=${debouncedEndDateTime}`;
    }

    authToken &&
      axios
        .get(url, {
          headers: {
            Authorization: `token ${authToken}`,
          },
        })
        .then(({ data }) => {
          setItems(data.data);
          setTotalRows(data.count);
        })
        .then((error) => {
          setError('No results found');
        })
        .catch((error) => {
          handleError(error);
        });
  }, [
    page,
    rowsPerPage,
    searchLogicalStatus,
    sortNameColumnDesc,
    sortNameColumnAsc,
    phoneNumberValue,
    leadNumberValue,
    authToken,
    handleError,
    numberOfConversations,
    debouncedStartDuration,
    debouncedEndDuration,
    debouncedStartDateTime,
    debouncedEndDateTime,
  ]);

  // Update items if new items
  useEffect(() => {
    // Get initial number of conversations during first render
    authToken &&
      axios
        .get('https://backend.staging-neuca.vee.ai/data/number_of_talks/', {
          headers: {
            Authorization: `token ${authToken}`,
          },
        })
        .then(({ data }) => {
          setNumberOfConversations(data.count);
        })
        .catch((error) => {
          handleError(error);
        });

    // Check number of conversations in interval
    const interval = setInterval(() => {
      authToken &&
        axios
          .get('https://backend.staging-neuca.vee.ai/data/number_of_talks/', {
            headers: {
              Authorization: `token ${authToken}`,
            },
          })
          .then(({ data }) => {
            data.count !== numberOfConversations && setNumberOfConversations(data.count);
          })
          .catch((error) => {
            handleError(error);
          });
    }, 30000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setSelectedItems([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortColumnDesc = (e) => {
    setSortNameColumnAsc('');
    setSortNameColumnDesc(e.currentTarget.name);
  };

  const sortColumnAsc = (e) => {
    setSortNameColumnDesc('');
    setSortNameColumnAsc(e.currentTarget.name);
  };

  const onStartDuration = (event) => {
    let x = event.target.value;
    if (event.target.value.replace(/[^0-9]/g, '').length <= 4) {
      if (event.target.value.length === 3 && !event.target.value.split('').includes(':')) {
        event.target.value = insert(x, 2, ':').join('');
      }
    }
    setStartDurationValue(event.target.value);
    setStartDuration(event.target.value.match(/\d+:[0-5]\d/));
  };

  const onEndDuration = (event) => {
    let x = event.target.value;
    if (event.target.value.replace(/[^0-9]/g, '').length <= 4) {
      if (event.target.value.length === 3 && !event.target.value.split('').includes(':')) {
        event.target.value = insert(x, 2, ':').join('');
      }
    }
    setEndDurationValue(event.target.value);
    setEndDuration(event.target.value.match(/\d+:[0-5]\d/));
  };

  const addDateTime = (values) => {
    const start = values.start.toISOString();
    const end = values.end.toISOString();
    setStartDateTime(start);
    setEndDateTime(end);
  };

  const addStartDateTime = (value) => {
    if (value instanceof Date && !isNaN(value)) {
      const start = value.toISOString();
      setStartDateTime(start);
    }
  };

  const addEndDateTime = (value) => {
    if (value instanceof Date && !isNaN(value)) {
      const end = value.toISOString();
      setEndDateTime(end);
    }
  };

  const addSelectedLogical = (selected) => {
    setSearchLogicalStatus(selected.join(','));
    setSelectedLogicalOptions(selected);
  };

  const onPhoneNumber = (e) => {
    setPhoneNumberValue(e.target.value);
  };

  const onLeadNumber = (e) => {
    setLeadNumberValue(e.target.value);
  };

  const resetDateTime = () => {
    setStartDateTimeValue(new Date());
    setEndDateTimeValue(new Date());
    setStartDateTime('');
    setEndDateTime('');
    setSortNameColumnAsc('');
    setSortNameColumnDesc('');
  };

  const resetDuration = () => {
    setStartDuration('');
    setEndDuration('');
    setStartDurationValue('00:00');
    setEndDurationValue('59:59');
  };

  const resetAll = () => {
    setPhoneNumberValue('');
    setLeadNumberValue('');
    setSearchLogicalStatus('');
    setSelectedLogicalOptions([]);
    resetDuration();
    resetDateTime();
    setSelectedItems([]);
  };

  useEffect(() => {
    if (!!selectedItems.length && authToken) {
      setIsDownloadingAll(true);
      (async () => {
        const result = await fetch(`${api}data/download_package_of_logs/?logs=${selectedItems.join(',')}`, {
          headers: {
            Authorization: `token ${authToken}`,
          },
        });
        const blob = await result.blob();
        if (result) {
          setIsDownloadingAll(false);
        }
        if (blob) {
          setDownloadSelectedLogsLink(URL.createObjectURL(blob));
        } else {
          console.warn('Error while downloading a blob');
        }
      })();
    }
  }, [selectedItems, authToken]);

  return (
    <Container>
      <Button onClick={resetAll}>Reset all</Button>
      {selectedItems.length === 0 || isDownloadingAll ? (
        <LoadingButton disabled loading={isDownloadingAll}>
          <FontAwesomeIcon icon={faDownload} style={{ margin: '0 5px 0 0' }} /> selected
        </LoadingButton>
      ) : (
        <a
          target="_blank"
          rel="noreferrer"
          href={downloadSelectedLogsLink}
          download={`export_logs_${selectedItems.join('_')}.json`}
        >
          <Button>
            <FontAwesomeIcon icon={faDownload} style={{ margin: '0 5px 0 0' }} /> selected
          </Button>
        </a>
      )}

      <TableComponent
        items={items}
        error={error}
        sortColumnDesc={sortColumnDesc}
        sortColumnAsc={sortColumnAsc}
        onStartDuration={onStartDuration}
        onEndDuration={onEndDuration}
        startDuration={startDurationValue}
        endDuration={endDurationValue}
        addDateTime={addDateTime}
        addSelectedLogical={addSelectedLogical}
        onPhoneNumber={onPhoneNumber}
        phoneNumberValue={phoneNumberValue}
        onLeadNumber={onLeadNumber}
        leadNumberValue={leadNumberValue}
        totalRows={totalRows}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        selectedLogicalOptions={selectedLogicalOptions}
        setSelectedLogicalOptions={setSelectedLogicalOptions}
        addStartDateTime={addStartDateTime}
        addEndDateTime={addEndDateTime}
        startDateTimeValue={startDateTimeValue}
        endDateTimeValue={endDateTimeValue}
        setStartDateTimeValue={setStartDateTimeValue}
        setEndDateTimeValue={setEndDateTimeValue}
        resetDateTime={resetDateTime}
        resetDuration={resetDuration}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
      />
    </Container>
  );
};

export default ItemsList;
