import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

export const TableWrapper = styled.div`
  border-left: 1px solid ${({ theme }) => theme.colors.lightGrey2};
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey2};
  border-right: 1px solid ${({ theme }) => theme.colors.lightGrey2};
  border-radius: 6px;
  background: white;
  margin-bottom: 50px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0px;
  table-layout: fixed;
`;

export const TableHeader = styled.thead`
  tr {
    font-size: 14px;

    &:nth-child(2) {
      text-align: left;
    }
    }
  }

`;

export const PaginationRow = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey2};

  td {
    padding: 0;
    .MuiTablePagination-toolbar {
      border: none;
    }
  }
`;

export const FirstRowHeader = styled.tr`
  th {
    text-align: left;
    padding: 10px 10px 0 10px;
    width: auto;
  }

  th:last-child {
    width: 160px;
  }
`;

export const SecondRowHeader = styled.tr`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey2};

  th:not(:last-child) {
    padding-bottom: 10px;
  }

  th {
    text-align: left;

    input {
      margin: 0 auto 0 10px;
    }
  }
`;

export const MinMaxCell = styled.th`
  text-align: left;
`;

export const SortBtn = styled.button`
  background: transparent;
  border: none;
  font-size: 14px;
  cursor: pointer;
  margin: 0;
  padding: 0 2px;

  &:not(:nth-child(2)) {
    margin-left: 8px;
  }

  &:hover {
    color: #000;
  }
`;

export const DurationTable = styled.table`
  border-spacing: 0;
  tbody {
    tr:last-child {
      th {
        padding-bottom: 10px;
      }
    }
  }
  th {
    border: none;
    font-size: 14px;

    label {
      display: flex;
      align-items: center;
      font-weight: 400;
    }
  }
`;

export const TableDateTime = styled.table`
  th {
    border: none;
    font-size: 12px;
  }
`;

export const StyledInput = styled.input`
  padding: 6px;
  border-radius: 2px;
  min-height: 35px;
  font-size: 15px;
`;

export const StatusCell = styled.th`
  text-align: left !important;
  color: #000;
  font-weight: 400;

  & > div {
    font-size: 13px;
    display: inline-block;
    margin: 0 auto 0 10px;
  }
`;

export const DateTimeCell = styled.th`
  display: flex;
  border: none;
  margin-left: 10px;
  font-weight: 400;
  .form-control {
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
    font-size: 13px;
    width: 65%;
  }
`;

export const MultiSelectWrapper = styled.div`
  div > button {
    padding: 6px;
    background-color: #fff;
    border-radius: 4px;
    border-width: 0;
    line-height: 1;
    border: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
    cursor: pointer;
    box-shadow: none;
    font-size: 14px;
    height: 29px;
  }
`;

export const DateTimePickerWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    width: 40px;
    margin-right: 10px;
  }
`;

export const dateTimeTextFieldStyle = {
  width: '140px',
  height: '17px',
  '.MuiInputBase-root': {
    height: '17px',
  },
  '.MuiOutlinedInput-input': {
    padding: '0px',
    fontSize: '12px',
    lineHeight: '16px',
  },
  '.MuiInput-underline:after': {
    borderBottomColor: 'black',
  },
  '& .MuiOutlinedInput-root ': {
    '&.Mui-focused fieldset': {
      borderColor: 'black',
    },
  },
  '.MuiButtonBase-root': {
    padding: '2px',
    '.MuiSvgIcon-root': {
      width: '15px',
      height: '15px',
    },
  },
};
