import { ErrorBoundary as ErrorBoundaryHandler } from 'react-error-boundary';

const ErrorBoundary = ({ children, setIsLoggedIn }) => {
  const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    );
  };

  const myErrorHandler = (error) => {
    if (error.response?.status === 401) {
      setIsLoggedIn(false);
    }
  };

  return (
    <ErrorBoundaryHandler
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      onError={myErrorHandler}
    >
      {children}
    </ErrorBoundaryHandler>
  );
};

export default ErrorBoundary;
