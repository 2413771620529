const AppReducer = (state, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return {
        ...state,
        authToken: action.payload,
      };
    case 'SET_PERMISSION_LEVEL':
      return {
        ...state,
        permissionLevel: action.payload,
      };

    default:
      return state;
  }
};

export default AppReducer;
