import styled from 'styled-components';

export const Wrapper = styled.tr`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 200px;

  td {
    p {
      color: ${({ theme }) => theme.colors.error};
      font-size: ${({ theme }) => theme.fontSize.l};
    }
  }
`;
