import styled from 'styled-components';

export const TableRow = styled.tr`
  height: 36px;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey2};

  &:nth-child(odd) {
    background: ${({ theme }) => theme.colors.veryLightBlue2};
  }

  & {
    background: ${({ theme, isLastOpenItem }) => isLastOpenItem && theme.colors.veryLightBlue} !important;
  }

  & > td {
    padding: 5px 5px 5px 10px;

    font-size: ${({ theme }) => theme.fontSize.s};
  }

  &:hover td {
    background: ${({ theme }) => theme.colors.bgLightGrey};
  }
`;

export const LeadNumberCell = styled.td`
  text-align: left;
`;

export const PrefixPhone = styled.p`
  color: ${({ theme }) => theme.colors.grey};
  display: inline-block;
  margin: 0 5px;
`;

export const CenterCell = styled.td`
  text-align: left;
`;
