import axios from 'axios';

// export const api = 'http://195.177.217.117:5555/';
export const api = 'https://backend.staging-neuca.vee.ai/';
export const apiRecords = 'https://nagraniadev.staging-neuca.vee.ai/';

export const loginUser = (credentials) => {
  const loginData = axios
    .post(`${api}core/login/`, credentials)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return loginData;
};

export const logoutUser = (authToken) => {
  const logoutData = axios
    .get(`${api}core/logout/`, {
      headers: {
        Authorization: `token ${authToken}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return logoutData;
};

export const ChangePassword = (authToken, data) => {
  const logoutData = axios
    .patch(`${api}core/change_password/`, data, {
      headers: {
        Authorization: `token ${authToken}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return logoutData;
};
