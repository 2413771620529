import React from 'react';

import Button from '../../atoms/Button/Button';

import { StyledLink, Wrapper } from './Menu.styles';

const Menu = ({ logout }) => {
  return (
    <Wrapper>
      {/* <StyledLink to="/items-list" exact>
        Table
      </StyledLink> */}
      <Button white onClick={() => logout()}>
        logout
      </Button>
    </Wrapper>
  );
};

export default Menu;
