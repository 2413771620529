import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { loginUser } from '../../../api/api';

import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import TextField from '../../atoms/TextFiled/TextField';
import Button from '../../atoms/Button/Button';

import logo from '../../../assets/images/vee_logo_for_light_bg.png';

import { StyledErrorMessage } from './Login.styles';

const Login = ({ setToken }) => {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [isErrorShown, setIsErrorShown] = useState(false);

  useEffect(() => {
    if (!!errorMessage?.length) {
      setIsErrorShown(true);
    } else {
      setIsErrorShown(false);
    }
  }, [errorMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!username?.length || !password?.length) {
      setErrorMessage('Enter username and password');
      return;
    }

    try {
      const token = await loginUser({ username: username, password: password });

      if (token?.response?.status === 401) {
        setErrorMessage(token.response.data.message);
      } else {
        setToken(token.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        mt={10}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box mb={4}>
          <img src={logo} alt="vee" />
        </Box>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, position: 'relative' }}>
          <Box mb={4}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete=""
              onChange={(e) => setUserName(e.target.value)}
              autoFocus
              variant="outlined"
              error={isErrorShown}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
              variant="outlined"
              error={isErrorShown}
            />
            {!!errorMessage?.length && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
          </Box>
          <Button white type="submit" fullWidth variant="contained" color="primary" sx={{ mt: 3, mb: 2 }}>
            Log In
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
