import { createContext, useReducer, useCallback } from 'react';
import AppReducer from './AppReducer';

const initialState = {
  authToken: '',
  permissionLevel: '',
};

export const GlobalContext = createContext(initialState);

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions for changing state

  const setAuthToken = useCallback((item) => {
    dispatch({
      type: 'SET_TOKEN',
      payload: item,
    });
  }, []);

  const setPermissionLevel = useCallback((item) => {
    dispatch({
      type: 'SET_PERMISSION_LEVEL',
      payload: item,
    });
  }, []);

  return (
    <GlobalContext.Provider
      value={{ authToken: state.authToken, permissionLevel: state.permissionLevel, setAuthToken, setPermissionLevel }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
