import styled from 'styled-components';

export const Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  pointer-events: ${({ isActive }) => (isActive ? 'all' : 'none')};
  transition: 0.2s;
  z-index: 9999;
`;

export const StyledContent = styled.div`
  width: 1200px;
  height: auto;
  padding: 10px;
  max-width: 90%;
  border-radius: 6px;
  background: ${({ theme }) => theme.colors.white};
  transition: 0.4s all;
  position: relative;
`;

export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;
