import React, { useEffect, useState, useContext, useRef } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import PropTypes from 'prop-types';
import axios from 'axios';

import { faDownload, faCopy } from '@fortawesome/free-solid-svg-icons';

import { GlobalTypes } from '../../../types/global';
import { GlobalContext } from '../../../context/GobalState';
import { api, apiRecords } from '../../../api/api';
import { formatDuration, formatPhoneNumber } from 'helpers';

import Button from '../Button/Button';
import Tree from '../../organisms/Tree/Tree';

import {
  StyledHeader,
  AudioRow,
  HeaderInfo,
  Icon,
  ConnectionInfo,
  StyledContent,
  ConversationLog,
  LogWrapper,
  ConnectionDetails,
  BorderContainer,
} from './ModalContent.styles';

// Dane odnośnie poszczególnego wiersza pobieramy gdy isActvie jest true, nie rzuca wtedy błędem
const ModalContent = ({ data, isActive, setLastOpenItemId }) => {
  const [isExpert, setIsExpert] = useState(false);
  const [connectionData, setConnectionData] = useState([]);
  const [showBorderTop, setShowBorderTop] = useState(false);
  const [showBorderBottom, setShowBorderBottom] = useState(false);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [talkId, setTalkId] = useState('');
  const [downloadLogLink, setDownloadLogLink] = useState('');
  const [recFilePath, setRecFilePath] = useState('');
  const [blobUrl, setBlobUrl] = useState('');
  const { authToken, permissionLevel } = useContext(GlobalContext);
  const handleError = useErrorHandler();
  const scroll = useRef();
  const audioRef = useRef();

  useEffect(() => {
    setLastOpenItemId(talkId);
  }, [setLastOpenItemId, talkId]);

  useEffect(() => {
    if (permissionLevel === GlobalTypes.PERMISSION_LEVEL) {
      setIsExpert(true);
    }
  }, [permissionLevel]);

  useEffect(() => {
    if (isActive && data && authToken) {
      axios
        .get(`${api}data/data/${data.map((el) => el.talk_id)}`, {
          headers: {
            Authorization: `token ${authToken}`,
          },
        })
        .then(
          ({ data }) => {
            setConnectionData(data);
            data.rec_file_path ? setRecFilePath(data.rec_file_path) : setRecFilePath('');
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .catch((error) => {
          handleError(error);
        });
    }

    if (!isActive) {
      setConnectionData([]);
    }
  }, [data, isActive, authToken, handleError]);

  useEffect(() => {
    if (!isActive) {
      updateAudioPlayer('');
    }
  }, [isActive]);

  useEffect(() => {
    if (!!recFilePath && isActive) {
      (async () => {
        const headers = new Headers();
        const username = 'voicetel';
        const password = 'IGai7phoPh';
        headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));

        const result = await fetch(`${apiRecords}recordings${recFilePath}`, {
          headers: headers,
        });
        const blob = await result.blob();

        if (blob) {
          updateAudioPlayer(URL.createObjectURL(blob));
        } else {
          console.warn('Error while downloading a blob');
          updateAudioPlayer('');
        }
      })();
    } else {
      updateAudioPlayer('');
    }
  }, [recFilePath, isActive]);

  useEffect(() => {
    if (isActive && talkId) {
      (async () => {
        const result = await fetch(`${api}data/download_log/${talkId}/`, {
          headers: {
            Authorization: `token ${authToken}`,
          },
        });

        const blob = await result.blob();

        if (blob) {
          setDownloadLogLink(URL.createObjectURL(blob));
        } else {
          console.warn('Error while downloading a blob');
        }
      })();
    }
  }, [talkId, isActive, authToken]);

  useEffect(() => {
    data[0]?.talk_id && setTalkId(data[0].talk_id);
  }, [data]);

  const updateAudioPlayer = (sourceUrl) => {
    setBlobUrl(sourceUrl);
    setIsDownloadDisabled(sourceUrl ? false : true);
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.load();
    }
  };

  const onScroll = (e) => {
    const currentScrollY = e.target.scrollTop;
    if (currentScrollY > 0) {
      setShowBorderTop(true);
    } else {
      setShowBorderTop(false);
    }

    if (e.target.offsetHeight + currentScrollY >= e.target.scrollHeight) {
      setIsScrolledToBottom(true);
    } else {
      setIsScrolledToBottom(false);
    }
  };

  useEffect(() => {
    if (scroll.current.scrollHeight > scroll.current.clientHeight) {
      setShowBorderBottom(true);
    } else {
      setShowBorderBottom(false);
    }
  }, [connectionData]);

  const copyToClipboard = (text) => {
    const elem = document.createElement('textarea');
    elem.value = text;
    document.body.appendChild(elem);
    elem.select();
    document.execCommand('copy');
    document.body.removeChild(elem);
  };

  const { duration, lead_number, phone_number, start_time, logical_status } = connectionData;

  return (
    <>
      <StyledHeader>
        <HeaderInfo>
          <div>
            <span>Lead ID:</span>
            <p>{lead_number && lead_number}</p>
          </div>
          <div>
            <span>Phone number:</span>
            <p>{phone_number && formatPhoneNumber(phone_number)}</p>
          </div>
          <div>
            <span>Start time:</span>
            <p>{start_time && start_time}</p>
          </div>
          <div>
            <span>Logical status:</span>
            <p>{logical_status && logical_status}</p>
          </div>
          <div>
            <span>Duration:</span>
            <p>{duration && formatDuration(duration)}</p>
          </div>
        </HeaderInfo>
        <AudioRow>
          <audio ref={audioRef} controls style={{ margin: '0 24px 0 12px', position: 'relative' }}>
            <source src={blobUrl} type="audio/mpeg" />
          </audio>
          <div>
            {isDownloadDisabled ? (
              <Button disabled={true} contained white>
                <Icon icon={faDownload} /> Record
              </Button>
            ) : (
              <a
                target="_blank"
                rel="noreferrer"
                href={blobUrl}
                download={`export_record_${connectionData.lead_number}.mp3`}
              >
                <Button contained white>
                  <Icon icon={faDownload} /> Record
                </Button>
              </a>
            )}
            <a
              target="_blank"
              rel="noreferrer"
              href={downloadLogLink}
              download={`export_log_${connectionData.lead_number}.json`}
            >
              <Button contained white>
                <Icon icon={faDownload} /> Logs
              </Button>
            </a>
            <Button
              contained
              white
              onClick={() => copyToClipboard(`${window.location.origin}${window.location.pathname}/!${talkId}`)}
            >
              <Icon icon={faCopy} /> Copy link
            </Button>
          </div>
        </AudioRow>
      </StyledHeader>

      <StyledContent
        onScroll={onScroll}
        ref={scroll}
        showBorderTop={showBorderTop}
        showBorderBottom={showBorderBottom}
        isScrolledToBottom={isScrolledToBottom}
      >
        {connectionData?.data?.map((data, index) => {
          const { initial_context, reaction, next_context, timestamp } = data;
          return (
            <ConnectionInfo key={index}>
              <>
                <ConnectionDetails>
                  {timestamp && (
                    <p>
                      <span>Time:</span> {timestamp}
                    </p>
                  )}
                  {isExpert && (
                    <p>
                      <span>Inital context: </span>
                      {initial_context ? initial_context : 'Not available'}
                    </p>
                  )}
                  {isExpert && (
                    <p>
                      <span>Reaction: </span> {reaction ? reaction : 'Not available'}
                    </p>
                  )}
                  {isExpert && (
                    <p>
                      <span>New context: </span> {next_context ? next_context : 'Not available'}
                    </p>
                  )}
                </ConnectionDetails>
                <BorderContainer>
                  <LogWrapper>
                    <ConversationLog>
                      {data.log.map((data, index) => {
                        return Array.isArray(data.children) ? (
                          <Tree key={`${index}${data.value}`} data={data} />
                        ) : (
                          <p key={`${index}`}>
                            <span>{`${data.key}`}</span> {`${data.value}`}
                          </p>
                        );
                      })}
                    </ConversationLog>
                  </LogWrapper>
                </BorderContainer>
              </>
            </ConnectionInfo>
          );
        })}
      </StyledContent>
    </>
  );
};

ModalContent.propTypes = {
  data: PropTypes.array.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ModalContent;
