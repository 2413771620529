import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input'; //--> https://www.npmjs.com/package/react-debounce-input

//Input z Debouncem
const StyledInput = styled(DebounceInput)`
  padding: 6px;
  ${({ small }) => small && 'padding: 0px'};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey2};
  width: 85%;
  font-size: 13px;
`;

const Input = ({ small, ...otherProps }) => {
  return <StyledInput small={small ? 1 : 0} {...otherProps} />;
};
export default Input;
