import { useEffect, useRef } from 'react';

import styled from 'styled-components';

import { insert, setInputFilter } from '../../../helpers';

const StyledInput = styled.input`
  border-radius: 4px;
  padding: 0 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGrey2} !important;
  font-size: 13px;
  width: 53px;
`;

const TimeInput = ({ value, onChange, ...otherProps }) => {
  const timeInputRef = useRef();

  // Allow digits and ':' only
  useEffect(() => {
    setInputFilter(document.getElementById('myTextBox'), function (value) {
      return /^\d*:?\d*$/.test(value);
    });
  });

  return (
    <StyledInput
      autoComplete="off"
      ref={timeInputRef}
      id="myTextBox"
      type="text"
      onChange={onChange}
      value={value}
      {...otherProps}
    />
  );
};

export default TimeInput;
